(function () {
  if (!window.console) {
    window.console = {};
  }
  // union of Chrome, FF, IE, and Safari console methods
  var m = [
    "log", "info", "warn", "error", "debug", "trace", "dir", "group",
    "groupCollapsed", "groupEnd", "time", "timeEnd", "profile", "profileEnd",
    "dirxml", "assert", "count", "markTimeline", "timeStamp", "clear"
  ];
  // define undefined methods as noops to prevent errors
  for (var i = 0; i < m.length; i++) {
    if (!window.console[m[i]]) {
      window.console[m[i]] = function () { };
    }
  }
})();

(function (global, undefined) {
  'use strict';

  var defaults = {
    genomeId: null,
    appId: '412802ed-8163-4642-a931-8299f209fecb',
    defaultLocale: 'en-US',
    defaultNextUrl: 'http://club.ubi.com/',
    baseUrl: 'https://connect.ubi.com/'
    //document.location.origin + '/'
    //https://cert2-connect.ubi.com/
    //'http://local.authentication-ui1.ubi.com'
    // 'https://uplayconnect.ubi.com/'
    // 'https://uat-uplayconnect.ubi.com/'
    // 'https://uat-connect.ubi.com/'
    // 'http://uat-uplayconnect.ubi.com/' // - uat
    // 'http://local.authentication-ui.ubi.com:64774/' // - local
    //'http://local.authentication-ui1.ubi.com'
  };

  // Wrapper function that allows passing it to define later
  var definition = function ($) {
    var uplay = {
      global: global,

      appId: defaults.appId,
      genomeId: null,
      baseUrl: defaults.baseUrl,
      lang: defaults.defaultLocale,
      nextUrl: defaults.defaultNextUrl,


      init: init,
      log: log,
      getTicket: getTicket,
      setTicket: setTicket,
      logout: logout,
      getProfile: getProfile,
      getProfiles: getProfiles,
      getUserData: getUserData,
      triggerEmailActivation: triggerEmailActivation,

      socket: null,
      callbacks: []
    };

    function init(appId, genomeId, lang, nextUrl, callback) {

      uplay.appId = appId == '' ? defaults.appId : appId;
      uplay.genomeId = genomeId == '' ? defaults.genomeId : genomeId;
      uplay.lang = lang == '' ? defaults.lang : lang;
      uplay.nextUrl = nextUrl == '' ? defaults.nextUrl : nextUrl;

      uplay.socket = new easyXDM.Socket({
        remote: uplay.baseUrl + "/Xdm/Index?appId=" + uplay.appId,
        remoteHelper: uplay.baseUrl + "/Scripts/name.html",
        swf: uplay.baseUrl + "/Scripts/easyxdm.swf",

        onMessage: function (message, origin) {
          var messageObj = { ref: "" };
          try {
            messageObj = JSON.parse(message);
          } catch (e) {
            console.warn(message);
          }
          var callback = $.grep(uplay.callbacks, function (item, idx) { return item.id == messageObj.ref; })[0];

          if (callback != null) {
            switch (messageObj.op) {
              case "getTicket":
                callback.f(messageObj);
                break;
              case "setTicket":
                callback.f(messageObj);
                break;
              case "logout":
                callback.f(messageObj);
                break;
              case "getProfile":
                callback.f(messageObj);
                break;
              case "getProfiles":
                callback.f(messageObj);
                break;
              case "getUserData":
                callback.f(messageObj);
                break;
              case "triggerEmailActivation":
                callback.f(messageObj);
              default:
            }
          }
        }
      });
      callback();
    }

    function getTicket(callback) {
      var d = new Date();
      var ref = uplay.appId + '_' + d.getMilliseconds();
      uplay.callbacks.push({ id: ref, f: callback });

      uplay.socket.postMessage(JSON.stringify({ op: "getTicket", appId: uplay.appId, genomeId: uplay.genomeId, ref: ref }));
    }

    function getProfile(ticket, callback) {
      var d = new Date();
      var ref = uplay.appId + '_' + d.getMilliseconds();
      uplay.callbacks.push({ id: ref, f: callback });
      uplay.socket.postMessage(JSON.stringify({ op: "getProfile", appId: uplay.appId, genomeId: uplay.genomeId, ticket: ticket, ref: ref }));
    }

    function getProfiles(ticket, callback) {
      var d = new Date();
      var ref = uplay.appId + '_' + d.getMilliseconds();
      uplay.callbacks.push({ id: ref, f: callback });
      uplay.socket.postMessage(JSON.stringify({ op: "getProfiles", appId: uplay.appId, genomeId: uplay.genomeId, ticket: ticket, ref: ref }));
    }

    function getUserData(ticket, userId, callback) {
      var d = new Date();
      var ref = uplay.appId + '_' + d.getMilliseconds();
      uplay.callbacks.push({ id: ref, f: callback });
      uplay.socket.postMessage(JSON.stringify({ op: "getUserData", appId: uplay.appId, genomeId: uplay.genomeId, ticket: ticket, userId: userId, ref: ref }));
    }

    function setTicket(ticket, token, callback) {
      var d = new Date();
      var ref = uplay.appId + '_' + d.getMilliseconds();
      uplay.callbacks.push({ id: ref, f: callback });

      uplay.socket.postMessage(JSON.stringify({ op: "setTicket", appId: uplay.appId, ticket: ticket, token: token, ref: ref }));
    }

    function logout(ticket, callback) {
      var d = new Date();
      var ref = uplay.appId + '_' + d.getMilliseconds();
      uplay.callbacks.push({ id: ref, f: callback });

      uplay.socket.postMessage(JSON.stringify({ op: "logout", appId: uplay.appId, ticket: ticket, ref: ref }));
    }

    function triggerEmailActivation(ticket, sessionId, callback) {
      var d = new Date();
      var ref = uplay.appId + '_' + d.getMilliseconds();
      uplay.callbacks.push({ id: ref, f: callback });
      uplay.socket.postMessage(JSON.stringify({ op: "triggerEmailActivation", appId: uplay.appId, genomeId: uplay.genomeId, ticket: ticket, sessionId: sessionId, ref: ref }));
    }

    function log(message, properties) {
      // comemnt this for PROD - disable logging to console
      //if (console != undefined) {
      //    console.log('--------------------');
      //    console.log(message);
      //    $(properties).each(function (idx, item) {
      //        console.log(item);
      //    });
      //    console.log('--------------------');
      //}
    }

    return uplay;
  };

  if (typeof define === 'function' && define.amd) {
    define('uplay', ['jquery'], definition);
  } else {
    global.uplay = definition(jQuery);
  }

}(window));

